@import '/app/src/theme/variables.module.less';

.signin-form-info {
  padding-top: 8px;
}
.signin-form-info-text {
  padding-top: 8px;
  margin: 0;
  &:first-child {
    padding-top: 0;
  }
}
