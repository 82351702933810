@import '/app/src/theme/variables.module.less';

.profitability {
  position: relative;

  width: 100%;
  max-width: 1920px;
  padding: 85px 0 135px;
  &.tablet {
    padding: 48px 0;
  }
  &.mobile {
    padding: 32px 0;
  }
}
.image-container {
  position: absolute;
  top: 0;
  left: 0;

  width: 34%;
  height: 100%;
  &.tablet {
    width: 50%;
  }
  &.mobile {
    display: none;
  }
}
.content {
  position: relative;
  z-index: 10;
}
.title {
  margin: 0 0 24px;
  &.tablet,
  &.mobile {
    margin: 0 0 8px;
  }
}
.subtitle {
  margin: 0 0 65px;
  &.tablet {
    margin: 0 0 40px;
  }
  &.mobile {
    margin: 0 0 25px;
  }
}
.option {
  display: flex;
  flex-flow: row;
  margin-bottom: 48px;
  &.mobile,
  &.tablet {
    margin-bottom: 26px;
  }
}
.option-title {
  margin-bottom: 8px;
}
.icon {
  font-size: 80px;
  &.tablet,
  &.mobile {
    font-size: 64px;
  }
}
