@import '/app/src/theme/variables.module.less';

@main-orange-10: #f85a00;
.proximity {
  position: relative;

  width: 100%;
  max-width: 1920px;
  padding: 85px 0;
  &.tablet {
    padding: 48px 0;
  }
  &.mobile {
    padding: 32px 0;
  }
}
.image-container {
  position: absolute;
  top: 0;
  left: 0;

  width: 34%;
  height: 100%;
  &.tablet {
    width: 50%;
  }
  &.mobile {
    display: none;
  }
}
.content-container {
  position: relative;
  z-index: 10;

  min-height: 450px;
}
.title {
  height: auto;
  margin: 0 0 24px;
  &.tablet,
  &.mobile {
    margin: 0 0 8px;
  }
}
.options {
  display: flex;
  flex-direction: column;
  gap: 48px;
  &.tablet,
  &.mobile {
    gap: 20px;
    width: 100%;
    max-width: none;
  }
  &-title {
    display: flex;
    align-items: center;
    margin: 35px 0;
    &.mobile {
      justify-content: center;
      margin: 25px 0;
    }
  }
  &-label {
    padding: 4px 8px;
    margin: 0 8px;
    white-space: nowrap;
    background-color: @main-orange-10;
    border-radius: 2px;
  }
}
.option {
  display: flex;
  flex-flow: row;
  width: 100%;
}
.icon {
  font-size: 80px;
  &.tablet,
  &.mobile {
    font-size: 64px;
  }
}
