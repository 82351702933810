@import '/app/src/theme/variables.module.less';

@blue-1: #f9fafb;
.signin-form {
  background: @blue-1;
  border-radius: 6px;
}
.signin-form-size {
  &-middle {
    padding: 24px;
  }
  &-large {
    padding: 32px;
  }
}
.forgot-password-link {
  padding-bottom: 22px;
}
.actions {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
