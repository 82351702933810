@import '/app/src/theme/variables.module.less';

@main-gray-3: #e7e9ea;
@font-size-base: 14px;
@gray-6: #737b7f;
@main-gray-7: #59666e;
.input {
  color: inherit;
  border-color: @main-gray-3;
  border-radius: 2px;
  transition:
    height 0s,
    padding 0s;
  &:global.ant-input-affix-wrapper-lg {
    padding-top: 8px;
    padding-bottom: 8px;
    line-height: 22px;
    input {
      font-size: @font-size-base;
    }
  }
  :global {
    .ant-input-lg {
      line-height: 22px;
    }
  }
  &.disabled {
    color: @gray-6;
  }
  &::placeholder {
    color: @main-gray-7;
  }
}
